import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import Classes from "../DashboardView/dashboard.module.css"
import { Link } from 'react-router-dom';
import QrList from "../../QR/list/qrList";
const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  useEffect(() => {

  }, []);
  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <QrList></QrList>
        {/*<Grid*/}
        {/*  container*/}
        {/*  spacing={3}*/}
        {/*>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={3}*/}
        {/*    sm={6}*/}
        {/*    xl={3}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <iframe className={Classes.iframeSmall} src="https://charts.mongodb.com/charts-cmpp-sdzbx/embed/charts?id=7b3052b5-e93f-4272-af96-9dc658377e94&theme=light"></iframe>*/}
        {/*  </Grid>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={3}*/}
        {/*    sm={6}*/}
        {/*    xl={3}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <iframe className={Classes.iframeSmall} src="https://charts.mongodb.com/charts-cmpp-sdzbx/embed/charts?id=943e16d8-7f0b-4df5-addf-2e39b00b2730&theme=light"></iframe>*/}
        {/*  </Grid>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={3}*/}
        {/*    sm={6}*/}
        {/*    xl={3}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <iframe className={Classes.iframeSmall} src="https://charts.mongodb.com/charts-cmpp-sdzbx/embed/charts?id=e51da30b-c25a-42ac-b43d-a2dcd9fd0bbd&theme=light"></iframe>*/}
        {/*  </Grid>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={3}*/}
        {/*    sm={6}*/}
        {/*    xl={3}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    /!*<TotalProfit />*!/*/}
        {/*  </Grid>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={7}*/}
        {/*    md={6}*/}
        {/*    xl={9}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <iframe className={Classes.iframe}  src="https://charts.mongodb.com/charts-cmpp-sdzbx/embed/charts?id=931db421-4b6f-44e4-9615-8101eb49aa67&theme=light"></iframe>*/}

        {/*  </Grid>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={5}*/}
        {/*    md={6}*/}
        {/*    xl={3}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <iframe className={Classes.iframe} src="https://charts.mongodb.com/charts-cmpp-sdzbx/embed/charts?id=0ff8455c-4747-45c8-b361-f286cc34f1ac&theme=light"></iframe>*/}
        {/*  </Grid>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={6}*/}
        {/*    md={6}*/}
        {/*    xl={3}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <iframe className={Classes.iframe} src="https://charts.mongodb.com/charts-cmpp-sdzbx/embed/charts?id=d137e080-7467-4aa0-82bc-c6a0fc7a526d&theme=light"></iframe>*/}

        {/*  </Grid>*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    lg={12}*/}
        {/*    md={12}*/}
        {/*    xl={9}*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <iframe className={Classes.iframe} src="https://charts.mongodb.com/charts-cmpp-sdzbx/embed/charts?id=28d6d262-aa03-4025-b0c9-7a86a620dd30&theme=light"></iframe>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Container>
    </Page>
  );
};

export default Dashboard;
