import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import axios from '../../../axios-agent';
import LinearProgress from '@material-ui/core/LinearProgress';
import crypto from 'crypto-js';
import { useNavigate } from 'react-router-dom';
const useStylesButton =  makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
const UserList=()=> {
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  let tempUser =  {
    hash: "",
    first_name: "",
    last_name: "",
    email: "",
    cause:"gf",
    role: "",
    reader_point: 0,
    referral_point: 0,
    author_point: 0,
    registered_time:"",
    cmpp_registered: false,
    url:""

  };


  const [data,setData] =useState([]) ;
  const [causes,setCauseList]=useState([])
  const options = {
    rowCursorHand: true,
    selectableRowsHideCheckboxes:true,
  };
  const btnClasses = useStylesButton();  useEffect(()=>{
    if (data.length>0){
      setLoading(false)
    }
  },[data])

  function showDetail(index) {
    const hash= crypto.MD5(data[index][2]).toString()
    navigate('/app/user/'+hash)

  }

  const columns = [
    {
      label: "FirstName",
      name: "FirstName",
      options: {
        filter: true,
      }
    },
    {
      name: "LastName",
      options: {
        filter: false,
      }
    },
    {
      name: "Email",
      options: {
        filter: true,
      }
    },
    {
      name: "Cause",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Role",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Reader Point",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Referral Point",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Author Point",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Edit",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (

            <Button
              variant="outlined"
              style={{borderColor:"blue"}}
              size="small"
              className={btnClasses.margin}
              onClick={() => {
                showDetail(dataIndex)
              }}
            >
              EDIT
            </Button>
          );
        }
      }
    },
  ];
  //Load causes
  useEffect(()=>{

    let tempData=[];
    axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')

    axios.get('/admin/user').then(response => {
      let tempUserList = response.data;
      if(tempUserList.length>0){
        tempUserList.map(user=>{

          const dataCell=Object.values(user)
          dataCell.splice(dataCell.length-1, 1)
          dataCell.splice(0, 2)
          tempData.push(dataCell)
        });
        console.log(tempData)
        setData(tempData)
      }
      console.log(response.data);
    }).catch(error => {})

  },[])

  return(
    <div style={{margin:"20px"}}>
      <MUIDataTable
        title={"User List"}
        data={data}
        columns={columns}
        options={options}
      />
      {loading? <LinearProgress />:null}
    </div>
  )
}
export default UserList;