import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import JobListView from 'src/views/Jobs/List';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import CauseList from './views/Cause/List/CauseList';
import CauseNew from './views/Cause/New/causeNew';
import UserList from './views/User/List/UserList';
import UserDetail from './views/User/Detail';
import CauseDetail from './views/Cause/Detail';
import Account from './views/account/AccountView/Profile';
import Avatar from '../src/views/User/avatar/index'
import Grant from './views/grant/grant';
import JobNew from './views/Jobs/New/jobNew';
import MyJob from './views/Jobs/MyJob/my-job';
import JobDetail from './views/Jobs/Detail/detail';
import AuthContextProvider from './context/auth-context';
import Search from "./views/Search/search";
import SearchCause from "./views/Search/search-cause/search-cause";
import Converter from "./views/QR/new/Converter";
import QrList from "./views/QR/list/qrList";
import QrEdit from "./views/QR/edit/edit";
const routes =(isLoggedIn) =>[
  {
    path: "/register",element: <RegisterView></RegisterView>
  },
  {
    path: "/grant" ,element: <Grant></Grant>
  }
  ,
  {
    path: "/upload/avatar",element: <Avatar></Avatar>
  },
  {
    path: "job",children: [
      {path: 'list', element: <JobListView></JobListView>},
      {path: 'new',element:<AuthContextProvider> <JobNew></JobNew></AuthContextProvider>},
      {path:':id' ,element: <JobDetail></JobDetail>},


    ]
  },
  {
    path: '/app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [

      {path: 'links-and-qr',element: <Converter/>},
      {path: 'qr-list',element: <QrList></QrList>},
      {path: 'qr/:hash',element: <QrEdit></QrEdit>},
      {path: 'user',element: <UserList></UserList>},
      {path: 'user/:hash',element: <UserDetail></UserDetail>},
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'account', element: <Account /> },
    ],
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
  // {
  //   path: '/',
  //   element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
  //   children: [
  //     { path: 'login', element: <LoginView /> },
  //     { path: '/', element: <Navigate to="/login" /> },
  //   ],
  // },
  { path: '*', element: <NotFoundView/> },

];
//
//   {
//     path: 'app',
//     element: <DashboardLayout />,
//     children: [

//     ]
//   },
//   {
//     path: '/',
//     element: <MainLayout />,
//     children: [
//       { path: 'login', element: <LoginView /> },
//       { path: 'register', element: <RegisterView /> },
//       { path: '404', element: <NotFoundView /> },
//       { path: '/', element: <Navigate to="/app/dashboard" /> },
//       { path: '*', element: <Navigate to="/404" /> }
//     ]
//   }
// ];

export default routes;
