import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Style from '../NavBar/navbar.module.css';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon, Codepen, Codesandbox, DollarSign,
  Lock as LockIcon, PlusSquare,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  List as ListIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon, ExternalLink, Link2
} from 'react-feather';
import NavItem from './NavItem';
import { ListItem } from 'semantic-ui-react';
import axios from 'axios';
import {Link} from "@material-ui/icons";

const user = {
  avatar: '/static/images/avatars/avatar.png',
  jobTitle: 'Admin',
  name: 'Dear Kevin'
};

const items = [
  {
    href: '/app/links-and-qr',
    icon: Link2,
    title: 'Links & QR'
  },

];
const AdminItems=[
  {
    href: '/app/qr-list',
    icon: Link,
    title: 'QR List'
  },
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [firstname,setFirstName]=useState("");
  const [role,setRole]=useState("");
  const [avatar,setAvatar]=useState("")
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  useEffect(()=>{
    let firstnameTemp=localStorage.getItem('firstname')
    let roleTemp=localStorage.getItem('role')
    let avatarTemp=localStorage.getItem('avatar')
    if (firstnameTemp==null || firstnameTemp==undefined || firstnameTemp==""){
      setFirstName("Dear user")
    }else {
      setFirstName("Dear "+firstnameTemp)

    }
    if (roleTemp==null|| roleTemp=="undefined" || roleTemp==""){
        setRole("User")
    }
    if (avatarTemp==null){
        setAvatar("/static/images/avatars/avatar.png")
    }
    setAvatar(avatarTemp)
    setRole(roleTemp)

  })
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={avatar}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {firstname}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >

          {role}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <hr className={Style.border}></hr>
          { (role=="admin")?AdminItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            )):null}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >

      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
